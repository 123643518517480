<style scoped lang="scss">
.curriculum {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.curriculum-search {
  flex: none;
}

.curriculum-table {
  flex: 1;
  overflow: hidden;

  .table_center {
    height: calc(100% - 60px);
    overflow-y: scroll;
  }
}

.newcourses {
  .new-courses-content {
    width: 100%;
    background: #ffffff;
    padding: 50px 57px 43px;
    box-sizing: border-box;
  }
}

::v-deep {
  .newcourses .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}

.new-courses::after {
  content: "";
  width: 5px;
  height: 18px;
  background-color: #6340c8;
  border-radius: 2.5px;
  position: absolute;
  top: 26px;
  left: 25px;
}

::v-deep {
  .curriculum-search .renovate-btn.el-button--medium {
    width: 30px;
    height: 30px;
    font-size: 20px;
    background: #2ac293;
    border: none;
  }

  .el-button--medium.is-circle {
    padding: 0;
  }
}
// 状态
.normal {
  font-size: 14px;
  color: #40c84a;
}

.unusual {
  font-size: 14px;
  color: #e53f32;
}
.look-btn {
  width: 60px;
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: #6340c8;
  background-color: #f2edff;
  border-radius: 6px;
  border: solid 1px #c0adf9;

  &:hover {
    color: #ffffff;
    background-color: #6340c8;
    border: 1px solid #6340c8;

    &:before,
    &:after {
      background: #6340c8;
    }
  }
}

// 分页
.paging {
  padding: 10px 0;
  text-align: right;
}

// 新增课程
.new-courses {
  width: 100%;
  height: 68px;
  line-height: 68px;
  background-color: #f6f7fc;
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  position: relative;
  text-indent: 36px;
}

.new-courses::after {
  content: "";
  width: 5px;
  height: 18px;
  background-color: #6340c8;
  border-radius: 2.5px;
  position: absolute;
  top: 26px;
  left: 25px;
}

::v-deep {
  .curriculum-search .el-input--medium .el-input__inner {
    min-width: 250px;
    // height: 50px;
    // line-height: 50px;
    background: #f6f7fc;
    border-radius: 10px;
    border: none;
  }

  .el-range-editor--medium.el-input__inner {
    background: #f6f7fc;
    border-radius: 10px;
    border: none;
  }

  .el-range-editor--medium .el-range-separator {}

  .el-date-editor .el-range-input {
    background: #f6f7fc;
  }

  .curriculum-search .el-form-item--medium .el-form-item__label {
    line-height: 50px;
  }

  .curriculum-search .el-form--inline .el-form-item {
    margin-right: 19px;
  }
}

::v-deep {
  .operate-btn .el-button--medium {
    width: 110px;
    height: 42px;
  }
}

// 导入导出
.operate-btn {
  margin: 20px 0;
  text-align: end;
}

// 导入数据
.leadingin {
  .leadingin-header {
    box-sizing: border-box;
    height: 60px;
    line-height: 20px;
    padding: 20px 25px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    display: flex;
    justify-content: space-between;

    .el-icon-close {
      font-size: 20px;
      color: #8c8c8c;
      cursor: pointer;
      transition: all 0.5s;
    }

    .el-icon-close:hover {
      font-weight: 600;
      color: #5f5f5f;
    }
  }

  .leadingin-pointer {
    height: 50px;
    background: #f4f5f5;
    line-height: 50px;
    text-indent: 25px;
    color: #91969c;
  }
}

.theme {
  padding: 0 50px;
  box-sizing: border-box;

  .theme-title {
    font-size: 18px;
    font-weight: 600;
    padding: 24px 0;

    color: #333333;
    display: flex;
    // justify-content: ;
    align-items: center;

    .theme-title-i {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #ffbd0d;
      color: #ffffff;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin-right: 10px;
    }
  }
}

.theme-type-list {
  background: #f4f5f5;
  border-radius: 10px;
  padding: 15px 25px;
  color: #a8abb0;
  line-height: 30px;

  .round-list {
    position: relative;
    text-indent: 8px;
  }

  .round-list::after {
    content: "";
    width: 2px;
    height: 2px;
    background: #a8abb0;
    position: absolute;
    left: 0;
    top: 15px;
  }
}

.theme-btn {
  margin-top: 15px;
  text-align: center;
}

.leadingin-footer {
  display: flex;
  height: 60px;
  border-top: 1px solid #f6f6f6;
  justify-content: space-between;
  padding: 0 26px;
  margin-top: 20px;
}

::v-deep {
  .leadingin-pointer .el-button--text {
    color: #feaf2f;
  }

  .leadingin-footer .el-button--text {
    color: #676c75;
  }

  .theme-btn .el-button--medium {
    width: 225px;
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .leadingin-footer .el-button--medium {
    height: 40px;
    margin-top: 10px;
  }

  .curriculum-table .el-button--medium {
    height: 30px;
    padding: 0 16px;
  }
}
</style>
<template>
  <section class="curriculum">
    <div class="curriculum-search">
      <el-form :inline="true" :model="searchform" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="searchform.query_word" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="searchform.arole_id" placeholder="请选择" clearable>
            <el-option v-for="item in roleList" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchform.eduser_status" placeholder="请选择" clearable> 
            <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('seacrh')" class="lightpurple-btn">查询</el-button>
          <!-- <el-button type="primary" @click="clae" class="renovate-btn" icon="el-icon-refresh" circle></el-button> -->
        </el-form-item>
      </el-form>
    </div>

    <div class="operate-btn">
      <el-button type="primary" class="lightblue-btn" @click="handelAdd">新增</el-button>
      <el-button type="primary" class="lightpurple-btn"  v-loading="btnLoad" @click="exportData">导出</el-button>
      <!-- <el-button type="primary" class="lightgreen-btn">重置密码</el-button> -->
    </div>

    <div class="curriculum-table">
      <template>
        <div class="table_center">
          <el-table :data="tableData" style="width: 100%" :header-cell-style="{
            background: '#E8EAF3',
            color: '#1a1a1b',
            'text-align': 'center',
            fontWeight: 500,
          }">
            <el-table-column prop="eduser_name" label="姓名"> </el-table-column>
            <el-table-column prop="eduser_account" label="账号"> </el-table-column>
            <el-table-column prop="arole_name" label="角色"> 
              <template slot-scope="scope">
              <p>{{ scope.row.is_super == 10 ? "-" : scope.row.arole_name }}
              </p>
            </template>
            </el-table-column>
            <el-table-column prop="eduser_status" label="账号状态"> 
              <template slot-scope="scope">
                <span :class="{ normal: scope.row.eduser_status == 10, unusual: scope.row.eduser_status == 20 }">{{
                scope.row.eduser_status == 10 ? "启用" : "禁用" }}</span>
                
              </template>
            </el-table-column>
            
            <el-table-column prop="eduser_phone" label="手机"> </el-table-column>

            <el-table-column prop="address" label="操作" width="260">
              <template slot-scope="scope" >

                <el-button type="custom_primary" :disabled="scope.row.is_super == 10"  size="mini" plain @click="accountEdit(scope.row)">编辑</el-button>
                <el-button type="primary" :disabled="scope.row.is_super == 10"  class="green-btn" @click="accountDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="paging">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="searchform.pagesize"
            next-text="下一页" layout=" pager, next, jumper" :total="total">
          </el-pagination>
       
        </div>
      </template>
    </div>


    <el-dialog width="558px" :visible.sync="accountDialog" :show-close="false" class="newcourses">
      <div class="new-courses">
        {{ type == 'update' ? "编辑账号" : "新增账号" }}
      </div>

      <div class="dialog-container">
        <div class="new-courses-content">
          <el-form label-width="120px" ref="accountForm" :rules="accountRules" :model="accountForm">
            <el-form-item label="管理员名称" prop="eduser_name">
              <el-input v-model.trim="accountForm.eduser_name" maxlength="10" placeholder="请输入名称" />
            </el-form-item>
            <el-form-item label="账号" prop="eduser_account">
              <el-input v-model.trim="accountForm.eduser_account" maxlength="15" placeholder="请输入账号" />
            </el-form-item>
            <el-form-item label="手机号码" prop="eduser_phone">
              <el-input v-model.trim="accountForm.eduser_phone" maxlength="11" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model.trim="accountForm.eduser_password" type="password" maxlength="16"
                :placeholder="type == 'update' ? '不更改则不输入' : '不输入则默认密码'" />
            </el-form-item>
            <el-form-item label="所属角色" prop="rolearr">
              <el-select multiple v-model="accountForm.rolearr" placeholder="请选择角色">
                <el-option v-for="item in roleList" :key="item.key" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="默认密码" class="danger">
                        <p>{{defaultPassword}}</p>
                    </el-form-item>
            <el-form-item label="状态">
              <el-switch v-model="accountForm.eduser_status" :active-value="10" :inactive-value="20"
                :active-text="accountForm.eduser_status == 10 && '启用' || '禁用'" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer">
        <el-button type="custom_info" size="small" @click="accountDialog = false;">
          取 消
        </el-button>
        <el-button type="custom_primary" size="small" @click="handleConfirm">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 导入数据 -->
    <el-dialog :show-close="false" :visible.sync="leadinginVisible" class="leadingin" width="650px">
      <div class="leadingin-header">
        <span>导入数据</span>
        <i class="el-icon-close" @click="leadinginVisible = false"></i>
      </div>
      <div class="leadingin-pointer">
        <span>为确保导入数据的准确,请按照一下规则进行导入</span>
        <el-button type="text">了解更多</el-button>
      </div>

      <ul class="theme">
        <li>
          <div class="theme-title">
            <span class="theme-title-i">1</span>
            <span>下载表单模板</span>
          </div>
          <ol class="theme-type-list">
            <li class="round-list">
              按照 <span>规范示例.xlsx</span>在模板内录入数据:
            </li>
            <li class="round-list">只导入第一张工作表(sheet1)</li>
            <li class="round-list">请勿修改表格标题,防止导入失败</li>
          </ol>
          <p class="theme-btn">
            <el-button>下载表单模板</el-button>
          </p>
        </li>
        <li>
          <div class="theme-title">
            <span class="theme-title-i">1</span>
            <span>下载表单模板</span>
          </div>
          <ol class="theme-type-list">
            <li class="round-list">
              按照 <span>规范示例.xlsx</span>在模板内录入数据:
            </li>
            <li class="round-list">只导入第一张工作表(sheet1)</li>
            <li class="round-list">请勿修改表格标题,防止导入失败</li>
          </ol>
          <p class="theme-btn">
            <el-button type="primary" class="lightyellow-btn">上传文件</el-button>
          </p>
        </li>
      </ul>
      <div class="leadingin-footer">
        <el-button type="text">
          <i class="iconfont icon-chakan1"></i>查看操作记录</el-button>
        <el-button type="primary" class="grey-btn" @click="leadinginVisible = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 删除确认框 -->
    <dialog-bar v-model="sendVal" type="danger" title="确认删除" content="账号删除后无法找回，是否确认删除？"
      v-on:cancel="clickCancel()" @danger="clickDanger(delId)" @confirm="clickConfirm()" dangerText="确定"></dialog-bar>
  </section>
</template>

<script>
import { getUserList, accountAdd, accountEdit, accountInfo, accountRemove, roleselect,exportData } from "@api/user";

export default {
  name: "",
  components: {
    dialogBar: () => import("@/components/configbox.vue"), //引入弹框组件
  },

  data() {
    return {
      currentPage: 1,
      total: 120,
      type: 'add',
      delId: 0,//删除id
      
      accountRules: {
        eduser_name: [
          { required: true, message: "请输入账号名称", trigger: "blur" },
        ],
        eduser_account: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        // eduser_phone: [
        //   { required: true, message: "请输入手机号", trigger: "blur" },
        // ],
        rolearr: [
          {
            type: "array",
            required: true,
            message: "请选择角色",
            trigger: "change",
          }],

      },
      accountDialog: false,
      accountForm: {},
      roleList: [],
      defaultPassword: "",

      sendVal: false,
      searchform: {
        pageindex: 1,
        pagesize: 10,
      },
      btnLoad:false,

      options: [{ 
       value:'启用',
       key:10
      },{
        value:'禁用',
       key:20
      }],

      // 表格数据
      tableData: [],

      // 导入数据
      leadinginVisible: false,
    };
  },

  mounted() {
    // let doc = document.querySelector(".el-pagination");
    // let liElement = document.createElement("button");
    // liElement.setAttribute("class", "surebtn");
    // var liText = document.createTextNode("确定");
    // liElement.appendChild(liText);
    // doc.appendChild(liElement);
  },
  created() {
    this.getData();
    this.getRoleList();
  },
  methods: {
    onSubmit() { 
       this.getData('search');
    },

    handleClick() {
      this.$router.push({ path: "/course/info" });
      // this.$router.push({path: '/login?url=' + this.$route.path});
    },
     // 修改当前
     handleCurrentChange() {
      this.searchform.pageindex = this.currentPage;
      this.getData()
    },
     // 新增
     handelAdd() {
      this.accountForm =[];
      this.type ='add';
      this.accountDialog = true;
    
    
    },
    async getData(val) {
      if(val =='search'){
        this.searchform.pageindex = 1;
      }
      const res = await getUserList(this.searchform);
      this.tableData = res.data.data;
      this.total = res.data.allcount;
      this.defaultPassword=res.data.defaultPassword;
    },
    /** 获取角色列表 */
    async getRoleList() {
      const res = await roleselect({ key: "arole_id", value: "arole_name" });
      
      if (res.status == 200) {
        this.roleList = res.data;
      }
    },
        /** 添加账号 */
    async accountAdd() {
      this.type = "add";
      this.accountForm = {};
      this.accountDialog = true;
      this.$nextTick(() => {
        this.$refs.accountForm.clearValidate();
      });
    },
    /** 导出 */
    async exportData() {
      this.btnLoad = true;
            let { data } = await exportData(this.searchform);

            this.btnLoad = false;
            if (data.full_file_url) {
                this.$fileDownload(data.full_file_url)
            };
    },
    /** 编辑账号 */
    async accountEdit(row) {
      this.type = "update";

      const res = await accountInfo(row.eduser_id);
      if (res.status == 200) {
        this.accountForm = res.data;
        delete this.accountForm.scuse_password;
        this.accountDialog = true;
      }
    },
    /** 删除账号 */
    async accountDelete(row) {
      this.sendVal = true;
      this.delId = row.eduser_id;
    },
    /** 确认添加 */
    async handleConfirm() {

      this.$refs.accountForm.validate(async (valid) => {

        if (valid) {
          let params = {
            eduser_id: this.accountForm.eduser_id,
            eduser_name: this.accountForm.eduser_name,
            eduser_account: this.accountForm.eduser_account,
            scuse_password: this.accountForm.eduser_password,
            eduser_phone: this.accountForm.eduser_phone,
            eduser_status: this.accountForm.eduser_status,
            rolearr: this.accountForm.rolearr,
          };
          let res = {};
          if (this.type == "add") {
            res = await accountAdd(params);
          } else {
            params.scuse_id = this.accountForm.scuse_id;
            res = await accountEdit(params);
            
          }
          if (res.status != 200){
            this.$message({
            message: res.msg,
            type: "error",
          });
          return;
          };
          if (res.status == 200) {
            this.$message.success(this.type == "add" ? "添加成功" : "编辑成功");
            this.accountDialog = false;
            this.getData();
          }
        }
      });
    },
   
    //删除操作
    async clickDanger(ededu_id) {   
      let res = {};    
       res = await accountRemove(ededu_id);
       if (res.status != 200){
            this.$message({
            message: res.msg,
            type: "error",
          });
          return;
          }else {
            this.$message.success(res.msg);
            this.accountDialog = false;
            this.getData();
          }
    },
    //取消操作
    clickCancel(){
       this.sendVal = false;
       this.delId = 0;
    }
   
  },
};
</script>
